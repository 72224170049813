import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import HamburgerIcon from "../assets/ui/hamburger.png";
import Close from "../assets/ui/Close_Dark.svg";
import Logo from "../assets/images/tdr-symbol.png";

const MobileNavbar = ({ isAuthenticated, isAdmin }) => {
  const [open, setOpen] = useState(false);

  const openNavbar = () => {
    !open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = null);
    setOpen(!open);
  };

  return (
    <>
      <img
        className="mobile-navbar__icon"
        src={HamburgerIcon}
        onClick={openNavbar}
      />
      <AnimatePresence exitBeforeEnter>
        {open && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 0.3 }}
          >
            <div className="mobile-navbar__menu">
              <img
                className="mobile-navbar__menu__close"
                src={Close}
                onClick={openNavbar}
              />
              <div className="mobile-navbar__menu__links">
                <nav
                  className="container navbar container-md-padding"
                  style={{ position: "relative" }}
                >
                  <div className="navbar__logo">
                    <a
                      href="/"
                      className="block lg:hidden w-[1rem] mt-[0.84rem] ml-1"
                    >
                      <img src={Logo} alt="The Design Release" />
                    </a>
                  </div>
                </nav>
                <div className="pl-4">
                  <a href="/">Home</a>
                  <a href="/events">Events</a>
                  <a href="/works">Works</a>
                  {/* <a href="/jobs">Jobs</a> */}
                  <a href="/in-sites">IN&#8212;SITES Journal</a>
                  <a href="mailto:julia@thedesignrelease.com">Contact</a>
                </div>
                <div className="footer">
                  {/* <nav className="container" style={{ position: "relative" }}>
                    <div className="navbar__logo">
                      <a href="/">
                        <img src={Logo} alt="The Design Release" />
                      </a>
                    </div>
                  </nav> */}

                  <div className="grid grid-cols-2 gap-4 md:hidden">
                    <div className="footer__section__left">
                      <div className="footer__heading">Information</div>
                      <a className="footer__link">
                        <span>About Us</span>
                      </a>
                      <a className="footer__link">
                        <span>Privacy Policy</span>
                      </a>
                      <a className="footer__link">
                        <span>Terms and Conditions</span>
                      </a>
                      <a className="footer__link">
                        <span>Cookie Policy</span>
                      </a>
                      <a className="footer__link" href="/submissions">
                        <span>Submit an Event</span>
                      </a>
                    </div>
                    <div className="footer__section__right md:hidden">
                      <div className="footer__heading">Connect</div>
                      <a
                        className="footer__link"
                        href="https://www.instagram.com/thedesignrelease/"
                      >
                        <span>Instagram</span>
                      </a>
                      <a
                        className="footer__link"
                        href="https://www.linkedin.com/company/thedesignrelease/"
                      >
                        <span>LinkedIn</span>
                      </a>
                      <a
                        className="footer__link"
                        href="https://www.pinterest.com/thedesignrelease/"
                      >
                        <span>Pinterest</span>
                      </a>
                      <a
                        className="footer__link"
                        href="mailto:hello@thedesignrelease.com"
                      >
                        <span>Email</span>
                      </a>
                    </div>
                  </div>

                  <div className="footer__email-form">
                    <div className="footer__heading">Newsletter</div>
                    <iframe
                      src="https://thedesignrelease.substack.com/embed"
                      width="100%"
                      style={{
                        borderRadius: "10px",
                        paddingBottom: "0.5rem",
                        height: "154px",
                        background: "var(--colors-white)",
                        transform: "scale(90vw) translate(-1.5rem)",
                      }}
                      frameborder="0"
                      scrolling="no"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileNavbar;
